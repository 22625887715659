import React from 'react';

import { ItemHistory } from '../../components/views/history/itemHistory/itemHistory';
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <ItemHistory
                startDate='1 Jan 2020'
                Action={0}
            />
        </LayoutLoggedIn>
    );
}
